import React, { useEffect, useRef, useState } from 'react'
import { ErrorModal, SuccessModal, WarningModal } from '../../components/Modal'
import Layout from '../../components/Layout'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { mainApi } from '../../api'
import moment from 'moment'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useTranslation } from 'react-i18next'
import Main from '../../components/Main/index'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../utils/debounce'
import {
	message,
	Input,
	Button,
	Table,
	DatePicker,
	Select,
	AutoComplete,
} from 'antd'

const Recovery = () => {
	dayjs.extend(advancedFormat)
	dayjs.extend(customParseFormat)
	const dateFormat = 'YYYY-MM-DD'

	const errorRef = useRef()
	const successRef = useRef()
	const warningRef = useRef()

	const { getMe } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const [dateStart, setDateStart] = useState(
		dayjs().startOf('year').format(dateFormat),
	)
	const [dateEnd, setDateEnd] = useState(dayjs().format(dateFormat))
	const [client, setClient] = useState('')
	const [clientName, setClientName] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [imeiClient, setImeiClient] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [status, setStatus] = useState('')
	const [promisedDate, setPromisedDate] = useState('')
	const [executer, setExecuter] = useState([])
	const [maxPageSize, setMaxPageSize] = useState(10)
	const [doceAndInsData, setDoceAndInsData] = useState([])
	const [employeeID, setEmployeeID] = useState('')

	const fetchData = async ({ queryKey }) => {
		const [
			_,
			currentPage,
			imei,
			status,
			clientCode,
			client,
			dateStart,
			dateEnd,
			employeeID,
			pageSize,
		] = queryKey
		const { data } = await mainApi.get(`installments`, {
			params: {
				skip: currentPage,
				status: status,
				IntrSerial: imei || '',
				CardCode: client && clientCode ? clientCode : '',
				date1: dateStart ? dateStart : '',
				date2: dateEnd ? dateEnd + 'T22:00:00.000Z' : '',
				pageSize: pageSize,
				U_Employee: employeeID,
			},
		})
		return JSON.parse(data)
	}

	const { data = [], isLoading } = useQuery({
		queryKey: [
			'recovery',
			currentPage,
			imeiClient,
			status,
			clientCode,
			clientName,
			dateStart,
			dateEnd,
			employeeID,
			maxPageSize,
		],
		queryFn: fetchData,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const fetchBp = async ({ queryKey }) => {
		const [_, clientName] = queryKey
		const { data } = await mainApi.get(`businesspartners`, {
			params: {
				frozen: 'N',
				name: clientName,
				pageSize: '20',
			},
		})
		return JSON.parse(data)
	}

	const { data: bpData = [], isLoading: bpLoading } = useQuery({
		queryKey: ['clients', clientName],
		queryFn: fetchBp,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const [isDarkMode, setIsDarkMode] = useState(
		document.documentElement.classList.contains('dark'),
	)

	useEffect(() => {
		const observer = new MutationObserver(() => {
			setIsDarkMode(document.documentElement.classList.contains('dark'))
		})

		observer.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ['class'],
		})

		return () => observer.disconnect()
	}, [])

	// Common styles for all components

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.docEntry}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if (data.length >= maxPageSize) {
			setCurrentPage(currentPage + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (currentPage < 0) {
			alert(t('noOtherInfo'))
		} else {
			setCurrentPage(currentPage - 1)
		}
	}

	const onSelectClient = (value) => {
		const val = value.split(' ,/ ')[0]
		setClient(val)
		debouncedSet(val, setClientName)
		const selectedOption = bpData.find((client) => {
			const cardName = get(client, 'cardName', '')
			const cardCode = get(client, 'cardCode', '')

			return `${cardName} ,/ ${cardCode}` === value
		})

		if (selectedOption) {
			const cardCode = get(selectedOption, 'cardCode', '')
			setClientCode(cardCode)
			message.success(cardCode)
		}
	}

	const columns = [
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			text: 'center',
			// width: 150,

			render: (text, record) => {
				const dueDate = moment(record.DueDate).format('YYYY-MM-DD')
				return (
					<div
						className={
							record['INV6.PaidToDate'] === get(record, 'insTotal', 0)
								? 'size-7 rounded-full bg-green-400'
								: moment().diff(dueDate, 'days') < 60
									? 'size-7 rounded-full bg-red-200  '
									: moment().format('YYYY-MM-DD').diff(dueDate, 'days') < 150
										? 'size-7 rounded-full bg-red-400 '
										: 'size-7 rounded-full bg-black '
						}
					></div>
				)
			},
		},
		{
			title: t('clientCode'),
			dataIndex: 'cardCode',
			key: 'cardCode',
			text: 'center',
			// width: 300,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('clientName'),
			dataIndex: 'cardName',
			key: 'cardName',
			text: 'center',
			// width: 300,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},

		{
			title: t('product'),
			dataIndex: 'dscription',
			key: 'dscription',
			text: 'center',
			// width: 350,

			render: (text, record) => {
				return <span className="text-textColor">{text || '-'}</span>
			},
		},
		{
			title: t('debt'),
			key: 'debt',
			text: 'center',
			// width: 150,

			render: (text, record) => (
				<span className="text-textColor">
					{new Intl.NumberFormat('fr-FR', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})
						.format(get(record, 'docTotal', '') - record.paidToDate)
						.replace(',', '.')}{' '}
					USD
				</span>
			),
		},
		{
			title: t('dateOfSale'),
			dataIndex: 'dueDate',
			key: 'dueDate',
			text: 'center',
			// width: 150,

			render: (text) => (
				<span className="text-textColor">
					{moment(text).format('DD.MM.YYYY')}
				</span>
			),
		},
		{
			title: t('executor'),
			dataIndex: 'paidToDate',
			key: 'paidToDate',
			text: 'center',
			// width: 150,

			render: (text, record) => (
				<span className="text-textColor">
					{get(record, 'firstName', '')} {get(record, 'lastName', '')}
				</span>
			),
		},
		{
			title: t('U_DaftarBet'),
			key: 'U_DaftarBet',
			dataIndex: 'U_DaftarBet',
			text: 'center',
			// width: 150,

			render: (text, record) => (
				<span className="text-textColor">{text || '-'}</span>
			),
		},
		{
			title: t('status'),
			dataIndex: 'U_PROBLEM.Name',
			key: 'U_PROBLEM.Name',
			text: 'center',
			// width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('dateDeadline'),
			dataIndex: 'U_PromisedDate',
			key: 'U_PromisedDate',
			text: 'center',
			// width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('lastComment'),
			dataIndex: 'U_COMMENTS.U_Text',
			key: 'U_COMMENTS.U_Text',
			text: 'center',
			// width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('dateDeadline'),
			dataIndex: 'U_COMMENTS.U_CreateTime',
			key: 'U_COMMENTS.U_CreateTime',
			text: 'center',
			// width: 150,
			render: (text) => (
				<span className="text-textColor">
					{text ? moment(text).format('YYYY-MM-DD HH:mm') : '-'}
				</span>
			),
		},
	]

	const searchMain = [
		{
			tag: 'autocomplete',
			title: t('client'),
			value: client,
			loading: bpLoading,
			onChange: (val) => {
				const value = val.includes(' ,/ ') ? val.split(' ,/ ')[0] : val
				setClient(value)
				debouncedSet(val, setClientName)
			},
			options: bpData.map((client) => ({
				value: `${client.cardName} ,/ ${client.cardCode}`,
				label: `${client.cardName}`,
			})),
			placeholder: t('client'),
			onSelect: onSelectClient,
		},
		{
			tag: 'input',
			title: t('imei'),
			onChange: (e) => {
				debouncedSet(e.target.value, setImeiClient)
			},
			placeholder: t('imei'),
		},
		{
			tag: 'date',
			title: t('startDate'),
			value: dateStart ? dayjs(dateStart, dateFormat) : null,
			onChange: (date) => {
				setDateStart(date ? date.format('YYYY-MM-DD') : '')
			},
			placeholder: t('startDate'),
		},
		{
			tag: 'date',
			title: t('endDate'),
			value: dateEnd ? dayjs(dateEnd, dateFormat) : null,
			onChange: (date) => {
				setDateEnd(date ? date.format('YYYY-MM-DD') : '')
			},
			placeholder: t('endDate'),
		},
		{
			tag: 'date',
			title: t('dateDeadline'),
			value: promisedDate ? dayjs(promisedDate, dateFormat) : null,
			onChange: (date) => {
				setPromisedDate(date ? date.format('YYYY-MM-DD') : '')
			},
			placeholder: t('endDate'),
		},
		{
			tag: 'select',
			title: t('paymentStatus'),
			onChange: (val) => {
				debouncedSet(val, setStatus)
				message.success(val)
			},
			options: [
				{
					value: `all`,
					label: `Все`,
				},
				{
					value: `closed`,
					label: `${t('paid')}`,
				},
				{
					value: `open`,
					label: `${t('notPaid')}`,
				},
			],
			placeholder: t('paymentStatus'),
		},
		{
			tag: 'select',
			title: t('receiver'),
			onChange: (val) => {
				debouncedSet(val, setEmployeeID)
			},
			options: [
				{
					value: 'null',
					label: 'Без приемщика',
				},
				...executer.map((v, i) => ({
					value: get(v, 'EmployeesInfo.EmployeeID', 0),
					label: `${get(v, 'EmployeesInfo.FirstName', 'Name')} ${get(v, 'EmployeesInfo.LastName', 'Surename')}`,
				})),
			],
			placeholder: t('receiver'),
		},
		// : null),
	]

	useEffect(() => {
		getAllGetterMoney()
		doceAndIns()
	}, [])

	const getAllGetterMoney = () => {
		api
			.get(
				`$crossjoin(EmployeesInfo, Departments)?$filter=Departments/Name eq 'Undiruv' and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setExecuter(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const doceAndIns = () => {
		api
			.get(
				`SQLQueries('latePaymentsMin')/List?date1='${dateStart}T00:00:00Z'&date2='${dateEnd}T00:00:00Z'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setDoceAndInsData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	// const handleSort = (column) => {
	// 	let direction = 'ASC'
	// 	if (sortConfig.column === column && sortConfig.direction === 'ASC') {
	// 		direction = 'DESC'
	// 	} else if (
	// 		sortConfig.column === column &&
	// 		sortConfig.direction === 'DESC'
	// 	) {
	// 		direction = null
	// 	}
	// 	setSortConfig({ column, direction })
	// }

	// useEffect(() => {
	// 	recovery(dateStart, dateEnd)
	// }, [page, maxPage, sortConfig])

	// const AddExecutorsForInstallments = async () => {
	// 	setIsProtsent(true)
	// 	try {
	// 		const installments = await customFuncsApi
	// 			.get(`installments?U_Employee=null`, {
	// 				headers: {
	// 					Prefer: 'odata.maxpagesize=1000000',
	// 				},
	// 			})
	// 			.then((res) => res.data.value)

	// 		const chunkSize = 100
	// 		const totalInstallments = installments?.length

	// 		const sendChunk = async (chunk) => {
	// 			const chunkData = {
	// 				invoices: chunk.map((v) => ({
	// 					DocEntry: v.DocEntry,
	// 					InstallmentId: v.InstlmntID,
	// 				})),
	// 				dunners: executer.map((v) => v.EmployeesInfo.EmployeeID),
	// 			}
	// 			await customFuncsApi.post('assignDunners', chunkData)
	// 		}

	// 		for (let i = 0; i < totalInstallments; i += chunkSize) {
	// 			const chunk = installments.slice(i, i + chunkSize)
	// 			await sendChunk(chunk)
	// 			let protsent = Math.floor((i / totalInstallments) * 100)
	// 			setProgress(protsent)
	// 		}
	// 	} catch (error) {
	// 		console.error('Error processing installments in Recovery:', error)
	// 	} finally {
	// 		setIsProtsent(false)
	// 	}
	// }

	// const viewItem = (v) => {
	// 	const queryParams = new URLSearchParams({
	// 		DocEntry: JSON.stringify(v.DocEntry),
	// 		InstlmntID: JSON.stringify(v.InstlmntID),
	// 	}).toString()
	// 	window.open(`/viewRecoveryItem?${queryParams}`, '_blank')
	// }

	// const searchUser = (a = '') => {
	// 	setClientName(a)
	// 	setBusinessLoading(true)
	// 	const formattedName = a.includes("'") ? a.replace(/'/g, "''") : a

	// 	api
	// 		.get(
	// 			`BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${formattedName}') or contains(U_Telephone, '${formattedName}'))`,
	// 		)
	// 		.then((res) => {
	// 			const resData = get(JSON.parse(res.data), 'value', [])
	// 			if (
	// 				!(resData.length === 1) ||
	// 				!a.includes(resData[0].CardName) ||
	// 				!a.includes(resData[0].CardCode)
	// 			)
	// 				setCustomerData(resData)
	// 			if (
	// 				resData.length === 0 &&
	// 				(!a.includes(resData[0].CardName) || !a.includes(resData[0].CardCode))
	// 			) {
	// 				setCustomerData([{ CardName: 'Нет данных', CardCode: '' }])
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			// errorRef.current?.open(
	// 			//   get(JSON.parse(err.response.data), 'error.message', ''),
	// 			// )
	// 		})
	// 		.finally(() => {
	// 			setBusinessLoading(false)
	// 		})
	// }

	// const handleChange = (e) => {
	// 	const newSearchTerm = e.target.value
	// 	if (e.target.value?.includes(' , ')) {
	// 		setUserCardCode(e.target.value?.split(' , ')[1])
	// 	} else {
	// 		setUserCardCode('')
	// 	}
	// 	dispatch(setName(newSearchTerm))
	// 	setClientName(newSearchTerm)
	// }

	// const distribution = () => {
	// 	distributionMain(0, 50, 1)
	// 	setIsProtsent(true)
	// }

	// const distributionMain = (currentN, nextN, someN) => {
	// 	const dunners = executer.map((v) => {
	// 		return get(v, 'EmployeesInfo.EmployeeID', 0)
	// 	})
	// 	setProgress(0)
	// 	let someData = doceAndInsData.slice(currentN, nextN).map((v, i) => {
	// 		return {
	// 			DocEntry: v.DocEntry,
	// 			InstallmentId: v.InstlmntID,
	// 		}
	// 	})

	// 	customFuncsApi
	// 		.post(
	// 			`assignDunners`,
	// 			{
	// 				invoices: someData,
	// 				dunners,
	// 				Origin: window.origin,
	// 			},
	// 			{
	// 				timeout: 1000 * 60,
	// 				onUploadProgress: (progressEvent) => {
	// 					const { loaded, total } = progressEvent
	// 					let percent = Math.floor((loaded / total) * 100)
	// 					setProgress(+percent)
	// 				},
	// 			},
	// 		)
	// 		.then(() => {
	// 			if (Math.floor(doceAndInsData.length / 50) + 1 > someN) {
	// 				distributionMain(currentN + 50, nextN + 50, someN + 1)
	// 				setnumberProtsent(someN + 1)
	// 			} else {
	// 				alert("Amaliyot to'liq yuklandi")
	// 				setIsProtsent(false)
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			errorRef.current?.open(get(err, 'message', ''))
	// 			setIsProtsent(false)
	// 		})
	// }

	// const onRangeChange = (dates, dateStrings) => {
	// 	if (dates) {
	// 		const start = dates[0]
	// 		const end = dates[1]

	// 		setDateStart(start ? start.format('YYYY-MM-DD') : null)
	// 		setDateEnd(end ? end.format('YYYY-MM-DD') : null)
	// 	} else {
	// 		console.log('Clear')
	// 	}
	// }

	return (
		<Layout>
			{/* <RecoveryStyle>
				<div className="container">
					<div style={{ width: '100%' }}>
						<p className="topTitle mb-8">{t('recoverySchedulel')}</p>
						<div className="flex flex-col sm:flex-row  items-end gap-5 mt-10 w-full">
							<div className="relative w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('client')}
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									type="text"
									value={clientName}
									onChange={(e) => {
										handleChange(e)
									}}
								/>
								{clientName && (
									<div className="absolute top-15 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{businessLoading ? (
											<ClipLoader size={10} />
										) : (
											customerData.map((v, i) => {
												return (
													<div
														key={i}
														className="p-2 border-b cursor-pointer hover:bg-gray-100"
														onClick={() => {
															if (v.CardName === 'Нет данных') return
															dispatch(setName(`${v.CardName} , ${v.CardCode}`))
															setUserCardCode(v.CardCode)
															setClientName(`${v.CardName} , ${v.CardCode}`)
															setCustomerData([])
														}}
													>
														{v.CardName} , {v.CardCode}
													</div>
												)
											})
										)}
									</div>
								)}
							</div>
							<div className="flex flex-col w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('imei')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={imei}
									onChange={(e) => {
										setIMEI(e.target.value)
										dispatch(setImei(e.target.value))
									}}
								/>

								<datalist id="client">
									{customerData.map((v, i) => (
										<option
											key={i}
											value={`${get(v, 'CardName', '')} , ${get(
												v,
												'CardCode',
												'',
											)}`}
										/>
									))}
								</datalist>
							</div>

							<div className="flex flex-col w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('date')}
								</p>
								<RangePicker
									value={[
										dateStart ? dayjs(dateStart, dateFormat) : null,
										dateEnd ? dayjs(dateEnd, dateFormat) : null,
									]}
									format={dateFormat}
									onChange={onRangeChange}
									className='border-[1px] border-[#DFE2E9"] p-2 rounded-md min-w-[240px] w-full '
								/>
							</div>
							<div className="flex flex-col w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('dateDeadline')}
								</p>
								<input
									type="date"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={promisedDate}
									onChange={(v) => {
										setPromisedDate(v.target.value)
									}}
								/>
							</div>
							<div className="flex flex-col w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('paymentStatus')}
								</p>
								<select
									name="payed"
									id="payed"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={status}
									onChange={(v) => {
										setStatus(v.target.value)
										dispatch(setStatusStore(v.target.value))
									}}
								>
									<option value="all">Все</option>
									<option value="closed">{'Оплачено'}</option>
									<option value="open">{'Не оплачено'}</option>
								</select>
							</div>
							{(get(getMe, 'Department2.Name', '') === 'Undiruvchi1' ||
								get(getMe, 'Department2.Name', '') === 'Boshqaruv') && (
								<div className="flex flex-col ">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('receiver')}
									</p>
									<select
										name="executer"
										id="executer"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-[150px]'
										}
										onChange={(v) => {
											setEmployeeID(v.target.value)
											dispatch(setExecuterStore(v.target.value))
										}}
										value={employeeID}
									>
										<option></option>
										<option value="null">Без приёмщика</option>
										{executer.map((v, i) => {
											return (
												<option
													value={get(v, 'EmployeesInfo.EmployeeID', 0)}
													key={i}
													selected={
														v.EmployeesInfo.EmployeeID ===
														get(data, 'U_Employee', '')
													}
												>
													{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
													{get(v, 'EmployeesInfo.LastName', 'Surename')}
												</option>
											)
										})}
									</select>
								</div>
							)}

							<AntButton
								loading={isLoading}
								className={'bg-[#0A4D68] text-white h-10 w-full sm:w-[100px]'}
								onClick={() =>
									recovery(
										dateStart,
										dateEnd,
										promisedDate,
										userCardCode,
										sortConfig,
									)
								}
							>
								{t('search')}
							</AntButton>
						</div>
						<div className="mt-8 mb-2 flex flex-col md:flex-row max-w-md">
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-red-200"></div>
								<span> {'<'} 60 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-red-400"></div>
								<span> {'<'} 150 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-black"></div>
								<span> {'>'} 150 день</span>
							</div>
						</div>
						{isLoading ? (
							<ClipLoader
								loading={true}
								size={75}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						) : (
							<>
								<div className="overflow-y-auto">
									<table className="w-full text-sm text-left  text-gray-700 ">
										<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
											<tr>
												<th scope="col" className="px-6 py-4">
													{t('status')}
												</th>
												<th scope="col" className="px-6 py-4 text-center">
													{t('clientCode')}
												</th>
												<th scope="col" className="px-14 py-4 text-center">
													{t('clientName')}
												</th>
												<th scope="col" className="px-14	 py-4">
													{t('product')}
												</th>
												<th scope="col" className="px-14 py-4">
													{t('debt')}
												</th>
												<th scope="col" className="px-10 py-4 text-center">
													{t('dateOfSale')}
												</th>
												<th scope="col" className="px-6 py-4 text-center">
													{t('executor')}
												</th>

												<th
													scope="col"
													className="px-6 py-4 cursor-pointer"
													onClick={() => handleSort('U_DaftarBet')}
												>
													{t('pageNumber')}
													{sortConfig.column === 'U_DaftarBet'
														? sortConfig.direction === 'ASC'
															? '↑'
															: sortConfig.direction === 'DESC'
																? '↓'
																: ''
														: ''}
												</th>
												<th scope="col" className="px-6 py-4 text-center">
													{t('status')}
												</th>
												<th scope="col" className="px-6 py-4 text-center">
													{t('dateDeadline')}
												</th>
												<th scope="col" className="px-6 py-4 text-center">
													{t('lastComment')}
												</th>
												<th scope="col" className="px-6 py-4 text-center">
													{t('lastCommentDate')}
												</th>
											</tr>
										</thead>
										<tbody>
											{data.data.length > 0 ? (
												data.data.map((v, i) => {
													return (
														<tr
															key={i}
															onClick={() => viewItem(v)}
															className="bg-white border-b  hover:bg-gray-50 "
														>
															<td className="px-6 py-4 font-medium ">
																<div
																	className={
																		get(v, 'INV6.PaidToDate', 0) ===
																		get(v, 'InsTotal', 0)
																			? // hover:border hover:border-zinc-400
																				'size-7 rounded-full bg-green-200 '
																			: moment().diff(v.DueDate, 'days') < 60
																				? 'size-7 rounded-full bg-red-200  '
																				: moment().diff(v.DueDate, 'days') < 150
																					? 'size-7 rounded-full bg-red-400 '
																					: 'size-7 rounded-full bg-black '
																	}
																></div>
															</td>
															<td className="px-6 py-4 font-medium ">
																{get(v, 'CardCode', 'Код покупателя')}
															</td>
															<td className="px-6 py-4 text-center">
																{get(v, 'CardName', 'Имя покупателя')}
															</td>
															<td className="px-6 py-4 text-center">
																{get(v, 'Dscription', '')}
															</td>
															<td className="px-6 py-4 text-center">
																{new Intl.NumberFormat('fr-FR', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																})
																	.format(
																		get(v, 'InsTotal', '') -
																			v['INV6.PaidToDate'],
																	)
																	.replace(',', '.')}{' '}
																USD
															</td>
															<td className="px-6 py-4 text-center">
																{get(v, 'DueDate', '').slice(0, 10)}{' '}
															</td>
															<td className="px-6 py-4">
																{get(v, 'firstName', '')}{' '}
																{get(v, 'lastName', '')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'U_DaftarBet', '')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'U_PROBLEM.Name', '')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'U_PromisedDate', '')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'U_COMMENTS.U_Text', '')}
															</td>

															<td className="px-6 py-4">
																{get(v, 'U_COMMENTS.U_CreateTime', '')
																	? moment(
																			get(v, 'U_COMMENTS.U_CreateTime', ''),
																		).format('YYYY-MM-DD HH:mm')
																	: ''}
															</td>
														</tr>
													)
												})
											) : (
												<tr>
													<td colSpan="10">
														<Empty
															description={t('noInfo')}
															image={Empty.PRESENTED_IMAGE_SIMPLE}
														/>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							use	<div className="flex items-center justify-between flex-col sm:flex-row mb-5 mt-5 w-full gap-5">
									<div className="flex items-center w-full">
										<Button
											disabled={page === 0}
											className={'btn'}
											onClick={oldData}
										>
											{'<'}
										</Button>
										<p className={'mr-2'}>
											<p>{currentPage + 1}</p>
										</p>
										<Button
											disabled={data.data?.length < maxPage}
											className={'btn'}
											onClick={newDatas}
										>
											{'>'}
										</Button>
									</div>

									<div className="flex items-center w-full">
										<select
											value={maxPage}
											className="w-full  sm:w-[100px] h-10  flex rounded-lg text-center"
											onChange={(e) => {
												setMaxPage(e.target.value)
												setPage(0)
												setCurrentPage(0)
											}}
										>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="30">30</option>
											<option value="50">50</option>
											<option value="80">80</option>
										</select>
									</div>
									<div className="w-full">
										{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
										doceAndInsData?.length > 1 ? (
											<AntButton
												className={
													'bg-[#0A4D68] text-white h-10 w-full rounded-lg sm:w-auto'
												}
												loading={executorLoading}
												onClick={AddExecutorsForInstallments}
											>
												{t('addNewDebtors')}
											</AntButton>
										) : null}
									</div>
									<div className="w-full">
										{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
										doceAndInsData?.length > 1 ? (
											<AntButton
												className={
													'bg-[#0A4D68] text-white h-10 w-full rounded-lg sm:w-auto'
												}
												onClick={distribution}
												loading={isLoading}
											>
												{t('distributeAllDebtors')}
											</AntButton>
										) : null}
									</div>
								</div>
							</>
						)}

						{isProtsent ? (
							<>
								<p className="progres">{t('waitProgress')} </p>

								<Progressbar bgcolor="green" progress={progress} height={23} />
							</>
						) : null}
					</div>
				</div>
			</RecoveryStyle> */}

			{/* <Main
				currentPage={currentPage + 1}
				handlePreviousPage={oldData}
				handleNextPage={newDatas}
				hasMoreData={data.length === 10}
				columns={columns}
				data={data}
				loading={isLoading}
				search={searchMain}
				// handleRowClick={viewItem}
			/> */}

			<div className="m-5 ">
				<div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 items-end gap-5 sm:ml-5 mt-5">
					{searchMain?.map((input, idx) => {
						return (
							<div
								key={idx}
								className={`flex flex-col gap-2 ${
									input.tag === 'autocomplete' ? 'col-span-2' : ''
								}`}
							>
								<p className="text-black dark:text-white font-bold">
									{t(input.title)}
								</p>
								{input.tag === 'input' ? (
									<Input
										loading={input.loading}
										value={input.value}
										onChange={input.onChange}
										placeholder={input.placeholder}
										type="text"
										className="w-full sm:w-[auto] !dark:bg-black inputStyle"
										// style={componentStyles.input}
										allowClear
									/>
								) : input.tag === 'select' ? (
									<Select
										value={input.value}
										placeholder={input.placeholder}
										onChange={input.onChange}
										className="w-full sm:w-[auto] !dark:bg-black"
										options={input.options}
										// style={componentStyles.select}
										loading={input.loading}
										allowClear
									/>
								) : input.tag === 'autocomplete' ? (
									<AutoComplete
										value={input.value}
										placeholder={input.placeholder}
										onChange={input.onChange}
										className={`w-full   ${
											isDarkMode ? 'dark-autocomplete' : ''
										}`}
										loading={input.loading}
										// style={componentStyles.autoComplete}
										onSelect={input.onSelect}
										options={input.options}
										showSearch
									/>
								) : input.tag === 'date' ? (
									<DatePicker
										placeholder={input.placeholder}
										value={input.value}
										format="DD.MM.YYYY"
										onChange={input.onChange}
										// style={componentStyles.datePicker}
									/>
								) : input.tag === 'button' ? (
									<Button
										onClick={input.onClick}
										className="w-full h-[35px] bg-[#0A4D68] text-white sm:w-[100px]"
										loading={input.loading}
									>
										{input.placeholder}
									</Button>
								) : (
									''
								)}
							</div>
						)
					})}
				</div>

				<div className="mt-8 sm:ml-5 mb-2 flex flex-col gap-3 md:flex-row max-w-md">
					<div className="flex gap-2 items-center">
						<div className="size-6 rounded-full bg-red-200"></div>
						<span className="text-black dark:text-white"> {'<'} 60 день</span>
					</div>
					<div className="flex gap-2 items-center">
						<div className="size-6 rounded-full bg-red-400"></div>
						<span className="text-black dark:text-white"> {'<'} 150 день</span>
					</div>
					<div className="flex gap-2 items-center">
						<div className="size-6 rounded-full bg-black"></div>
						<span className="text-black dark:text-white"> {'>'} 150 день</span>
					</div>
				</div>

				<div className="mt-6 sm:ml-5 sm:mt-10">
					<div className=" mt-10 ">
						<Table
							columns={columns}
							dataSource={data}
							pagination={false}
							loading={isLoading}
							size="small"
							bordered={true}
							className="custom-table"
							// {...(handleRowClick && {
							// 	onRow: (record) => ({
							// 		onClick: () => handleRowClick(record),
							// 	}),
							// })}
							scroll={{ x: 'max-content' }}
							rowClassName={(record, index) =>
								index % 2 === 0
									? 'bg-white dark:bg-[#171930]'
									: 'bg-[#F7F7F7] dark:bg-[#1C1C1C]'
							}
						/>
					</div>
					<div className="flex items-center justify-between w-full">
						<div className="flex items-center gap-2  mt-5">
							<Button
								disabled={currentPage === 0}
								className={'bg-[#0A4D68] text-white rounded-xl'}
								onClick={oldData}
							>
								{'<'}
							</Button>
							<p className="text-black dark:text-white">{currentPage + 1}</p>
							<Button
								disabled={!data.length === maxPageSize}
								className={'bg-[#0A4D68] text-white rounded-xl'}
								onClick={newDatas}
							>
								{'>'}
							</Button>
						</div>
						<div className="flex flex-col items-center  gap-2 mt-1 !dark:bg-black">
							<p className="font-bold dark:text-white">{t('numberOfData')}</p>
							<Select
								value={maxPageSize}
								onChange={(val) => {
									setMaxPageSize(val)
									setCurrentPage(0)
								}}
								className="w-full sm:w-[70px] !dark:bg-black"
								options={[
									{
										value: '10',
										label: '10',
									},
									{
										value: '20',
										label: '20',
									},
									{
										value: '30',
										label: '30',
									},
									{
										value: '50',
										label: '50',
									},
									{
										value: '80',
										label: '80',
									},
								]}
								// style={componentStyles.select}
								// loading={input.loading}
								allowClear
							/>
						</div>
						{/* <div className="w-full">
							{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
							doceAndInsData?.length > 1 ? (
								<AntButton
									className={
										'bg-[#0A4D68] text-white h-10 w-full rounded-lg sm:w-auto'
									}
									loading={executorLoading}
									onClick={AddExecutorsForInstallments}
								>
									{t('addNewDebtors')}
								</AntButton>
							) : null}
						</div>
						<div className="w-full">
							{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
							doceAndInsData?.length > 1 ? (
								<AntButton
									className={
										'bg-[#0A4D68] text-white h-10 w-full rounded-lg sm:w-auto'
									}
									onClick={distribution}
									loading={isLoading}
								>
									{t('distributeAllDebtors')}
								</AntButton>
							) : null}
						</div> */}
					</div>
				</div>
			</div>

			<>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal getRef={(r) => (successRef.current = r)} />
				<WarningModal getRef={(r) => (warningRef.current = r)} />
			</>
		</Layout>
	)
}

export default Recovery
