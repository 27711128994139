import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Input, AutoComplete, Select, Button, DatePicker } from 'antd'
import '../../assets/antTable.css'
function Main({
	title = '',
	currentPage,
	handlePreviousPage,
	handleNextPage,
	hasMoreData,
	columns,
	data,
	loading,
	handleRowClick,
	onBtnClick,
	onStartDateChange,
	search = [],
	onEndDateChange,
	startDate,
	endDate,
	formattedDate,
	isSticky = true,
}) {
	const { t } = useTranslation()

	const [isDarkMode, setIsDarkMode] = useState(
		document.documentElement.classList.contains('dark'),
	)

	useEffect(() => {
		const observer = new MutationObserver(() => {
			setIsDarkMode(document.documentElement.classList.contains('dark'))
		})

		observer.observe(document.documentElement, {
			attributes: true,
			attributeFilter: ['class'],
		})

		return () => observer.disconnect()
	}, [])

	// Common styles for all components
	const commonStyles = {
		backgroundColor: isDarkMode ? '#000' : '#fff',
		color: isDarkMode ? '#fff' : '#000',
	}

	// Component specific styles
	const componentStyles = {
		input: {
			...commonStyles,
			'.ant-input ': {
				backgroundColor: isDarkMode ? '#000' : '#fff',
				color: isDarkMode ? '#fff' : '#000',
			},
		},
		select: {
			...commonStyles,
			':where(.css-dev-only-do-not-override-apn68).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector':
				{
					backgroundColor: isDarkMode ? '#000 !important' : '#fff !important',
					color: isDarkMode ? '#fff !important' : '#000 !important',
				},
		},
		autoComplete: {
			'& .ant-select-selector': {
				backgroundColor: isDarkMode ? '#000' : '#fff',
				color: isDarkMode ? '#fff' : '#000',
				borderColor: isDarkMode ? '#424242' : '#d9d9d9',
			},
			'& .ant-select-arrow': {
				color: isDarkMode ? '#fff' : '#000',
			},
			'& .ant-select-selection-placeholder': {
				color: isDarkMode ? '#888' : '#999',
			},
		},
		datePicker: {
			...commonStyles,
			'.ant-picker-input > input': {
				color: isDarkMode ? '#fff' : '#000',
			},
		},
	}

	return (
		<div className="m-5 ">
			<div className="flex flex-col sm:flex-row items-end gap-5 sm:ml-10 mt-5">
				{search?.map((input, idx) => {
					return (
						<div key={idx} className="flex gap-2 flex-col items-start">
							<p className="text-black dark:text-white font-bold">
								{t(input.title)}
							</p>
							{input.tag === 'input' ? (
								<Input
									loading={input.loading}
									value={input.value}
									onChange={input.onChange}
									placeholder={input.placeholder}
									type="text"
									className="w-full sm:w-[150px] !dark:bg-black inputStyle"
									style={componentStyles.input}
									allowClear
								/>
							) : input.tag === 'select' ? (
								<Select
									value={input.value}
									placeholder={input.placeholder}
									onChange={input.onChange}
									className="w-full sm:w-[200px] !dark:bg-black"
									options={input.options}
									style={componentStyles.select}
									loading={input.loading}
									allowClear
								/>
							) : input.tag === 'autocomplete' ? (
								<AutoComplete
									value={input.value}
									placeholder={input.placeholder}
									onChange={input.onChange}
									className={`w-full sm:w-[270px] ${
										isDarkMode ? 'dark-autocomplete' : ''
									}`}
									loading={input.loading}
									style={componentStyles.autoComplete}
									onSelect={input.onSelect}
									options={input.options}
									showSearch
								/>
							) : input.tag === 'date' ? (
								<DatePicker
									placeholder={input.placeholder}
									value={input.value}
									format="DD.MM.YYYY"
									onChange={input.onChange}
									style={componentStyles.datePicker}
								/>
							) : input.tag === 'button' ? (
								<Button
									onClick={input.onClick}
									className="w-full h-[35px] bg-[#0A4D68] text-white sm:w-[100px]"
									loading={input.loading}
								>
									{input.placeholder}
								</Button>
							) : (
								''
							)}
						</div>
					)
				})}
				{search?.button && (
					<div className="flex gap-2 flex-col items-start">
						<Button
							onClick={search?.button?.onClick}
							className="w-full h-[35px] bg-[#0A4D68] text-white sm:w-[100px]"
							loading={search?.button?.loading}
						>
							{search?.button?.placeholder}
						</Button>
					</div>
				)}
			</div>

			<div className="mt-6 sm:ml-10 sm:mt-10">
				<div className=" mt-10 ">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
						loading={loading}
						size="small"
						bordered={true}
						className="custom-table"
						{...(handleRowClick && {
							onRow: (record) => ({
								onClick: () => handleRowClick(record),
							}),
						})}
						scroll={{ x: 'max-content' }}
						rowClassName={(record, index) =>
							index % 2 === 0
								? 'bg-white dark:bg-[#171930]'
								: 'bg-[#F7F7F7] dark:bg-[#1C1C1C]'
						}
					/>
				</div>
				{handleNextPage && (
					<div className="flex items-center gap-2  mt-5">
						<Button
							disabled={currentPage === 1}
							className={'bg-[#0A4D68] text-white rounded-xl'}
							onClick={handlePreviousPage}
						>
							{'<'}
						</Button>
						<p className="text-black dark:text-white">{currentPage}</p>
						<Button
							disabled={!hasMoreData}
							className={'bg-[#0A4D68] text-white rounded-xl'}
							onClick={handleNextPage}
						>
							{'>'}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
export default Main
