import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorModal } from '../../components/Modal'
import { useNavigate } from 'react-router-dom'
import { main } from '../../store/slices'
import { get } from 'lodash'
import  { customFuncsApi } from '../../api'
import { useTranslation } from 'react-i18next'
import images from "../../assets/images/images.png"
import { Input } from 'antd';
import Button from '../../components/Button/index';
const Login = () => {
	const { setMe, setToken } = main.actions
	const { info } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const loginValue = get(info, 'login', '')
	const passwordValue = get(info, 'password', '')

	const errorRef = useRef()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [login, setLogin] = useState(loginValue)
	const [password, setPassword] = useState(passwordValue)
	const [isLoading, setIsLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	useEffect(() => {
		if (window.location.hostname.includes('localhost')) {
			setLogin('boshliq')
			setPassword('Sal0mTest')
		}
	}, [])

	const loginProfile = useCallback(() => {
		setIsLoading(true)

		customFuncsApi
			.post('/login', {
				UserName: login,
				Password: password,
			})
			.then((res) => {
				dispatch(setToken(get(res, 'data.SessionId', '')))
				// const token = get(res, 'data.SessionId', '')

				// customFuncsApi.defaults.headers.common['Set-Cookie'] =
				// 	`sessionToken=${token}; SameSite=None; Secure`
				// customMainApi.defaults.headers.common['Set-Cookie'] =
				// 	`sessionToken=${token}; SameSite=None; Secure`
				// document.cookie = `authToken=${token}; path=/; SameSite=None; Secure;`
				secure()
			})
			.catch((err) => {
				errorRef.current?.open(t('loginError'))
				setIsLoading(false)
			})
	}, [login, password])

	useEffect(() => {
		const handleGlobalKeyDown = (e) => {
			if (e.key === 'Enter') {
				loginProfile()
			}
		}
		window.addEventListener('keydown', handleGlobalKeyDown)
		return () => {
			window.removeEventListener('keydown', handleGlobalKeyDown)
		}
	}, [loginProfile])

	const secure = () => {
		customFuncsApi
			.get(`userData`)
			.then((res) => {
				const resData = res.data
				if (get(resData, 'EmployeeID', '')) {
					if (get(resData, 'Department2.Name', '') === 'Sotuv') {
						navigate('/cash-payment')
					} else if (get(resData, 'Department2.Name', '') === 'Undiruv') {
						navigate('/recovery')
					} else if (get(resData, 'Department2.Name', '') === 'Sherik') {
						navigate('/all-products-partner')
					} else if (get(resData, 'Department2.Name', '') === 'Scoring') {
						navigate('/scoring-clients')
					} else if (get(resData, 'Department2.Name', '') === 'Undiruvchi1') {
						navigate('/recovery')
					} else if (get(resData, 'Department2.Name', '') === 'Undiruv_kocha') {
						navigate('/recovery')
					} else {
						navigate('/dashboard')
					}
					dispatch(setMe(resData))
				} else {
					errorRef.current?.open(t('loginError'))
				}
				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				setIsLoading(false)
			})
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			loginProfile()
		}
	}

	return (
		<div>
			<div className='flex items-center justify-between bg-background'>
			 <div className='flex items-center justify-center gap-4 w-full p-5'>
				<div className=' flex flex-col items-start justify-center gap-10'>
					<h1 className='text-2xl font-bold text-black dark:text-white'>{t('login')}</h1>
					<div className='flex flex-col gap-2 w-full mt-10'>
					 <p className="text-textColor text-sm">{t('user')}</p>
					<Input
						value={login}
						type="text"
						onChange={(e) => setLogin(e.target.value)}
						onKeyDown={handleKeyDown}
						className="w-full sm:w-[400px] h-10 rounded-2xl"
						placeholder={t('login')}
					/>
					</div>
					<div className='flex flex-col gap-2 w-full'>
					 <p className="text-textColor text-sm">{t('password')}</p>
					<Input.Password
						value={password}
						className="w-full h-10 sm:w-[400px] rounded-2xl"
						type="password"
						onChange={(e) => setPassword(e.target.value)}
						onKeyDown={handleKeyDown}
						placeholder={t('password')}
					/>
					</div>
					<Button onClick={loginProfile} className="w-full h-10 rounded-2xl text-textColor" loading={isLoading} disabled={isLoading}>
						{isLoading ? `${t('loading')}...` : t('login')}
					</Button>
				</div>
				</div>

				<div className="flex flex-col items-center w-full none"><img src={images} className='w-full h-screen rounded-bl-[200px]' alt="Nasiya" srcset="" /></div>
			</div>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</div>
	)
}

export default Login
