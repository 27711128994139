import { createSlice } from '@reduxjs/toolkit'

export const main = createSlice({
	name: 'main',
	initialState: {
		getMe: {},
		language: 'uz',
		info: {},
		login: false,
		token: '',
		u_prefix: '',
		u_prefixO: '',
		date: '',
		collapse:"",
		selectedPath:"",
		openMiniMenu:"",
		theme:"",
		title:"",
		subTitle:""
	},
	reducers: {
		setLanguage: (state, action) => {
			state.language = action.payload
		},
		setTitle: (state, action) => {
			state.title = action.payload
		},
		setSubTitle: (state, action) => {
			state.subTitle = action.payload
		},
		setCollapse: (state, action) => {
			state.collapse = action.payload
		},
		setTheme: (state, action) => {
			state.theme = action.payload
		},
		setSelectedPath: (state, action) => {
			state.selectedPath = action.payload
		},
		setOpenMiniMenu: (state, action) => {
			state.openMiniMenu = action.payload
		},
		setInfo: (state, action) => {
			state.info = action.payload
		},

		setMe: (state, action) => {
			state.getMe = action.payload
		},
		setLogins: (state, action) => {
			state.login = action.payload
		},
		setToken: (state, action) => {
			state.token = action.payload
		},
		setU_prefix: (state, action) => {
			state.u_prefix = action.payload
		},
		setU_prefixO: (state, action) => {
			state.u_prefixO = action.payload
		},
		setDate: (state, action) => {
			state.date = action.payload
		},
	},
})
