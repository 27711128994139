import React, { memo, useState, useEffect } from 'react'
import ChangeDateStyle from './ChangeDateStyle'
import Modal from 'react-modal'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
}

const ChangeDate = ({ getRef = () => {}, onConfirm = () => {} }) => {
	const [date, setDate] = useState('')
	const [isOpenModal, setIsOpenModal] = useState(false)

	useEffect(() => {
		const ref = {
			open: (v) => {
				setDate(v)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<ChangeDateStyle>
				<div className="card">
					<h2>{'Изменить дату!'}</h2>
					<input
						type={'date'}
						placeholder="Data"
						className="input"
						value={date}
						onChange={(v) => {
							setDate(v.target.value)
						}}
					/>

					<div className="centerCard">
						<button className="btnN" onClick={() => setIsOpenModal(false)}>
							{'Нет'}
						</button>
						<button className="btnY" onClick={() => onConfirm(date)}>
							{'Да'}
						</button>
					</div>
				</div>
			</ChangeDateStyle>
		</Modal>
	)
}

export default memo(ChangeDate)
