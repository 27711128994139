import React, { useRef, useState } from 'react'
import { get } from 'lodash'
import Layout from '../../../components/Layout'
import { ErrorModal } from '../../../components/Modal'
import CreateUserModal from '../../../components/Modal/CreateUserInactive'
import UpdateUser from './UpdateUser'
import _ from 'lodash'
import { mainApi } from '../../../api/index'
import formatPhoneNumber from '../../../helpers/PhoneWithSpaces'
import { useTranslation } from 'react-i18next'
import Main from '../../../components/Main/index'
import { debouncedSet } from '../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Button from '../../../components/Button/index'

const NoActiveClients = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const errorRef = useRef()
	const updateRef = useRef()
	const createUserRef = useRef()
	const succesCreateUserRef = useRef()

	const [client, setClient] = useState('')
	const [clientName, setClientName] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [currentPage, setCurrentPage] = useState(0)
	const [phoneNumber, setPhoneNumber] = useState('')

	const fetchData = async ({ queryKey }) => {
		const [_, currentPage, clientName, phoneNumber] = queryKey
		const { data } = await mainApi.get(`businesspartners`, {
			params: {
				frozen: 'Y',
				name: clientName,
				pageSize: '10',
				telephone: phoneNumber,
				skip: currentPage,
			},
		})
		return JSON.parse(data)
	}

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['active-clients', currentPage, clientName, phoneNumber],
		queryFn: fetchData,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const fetchBp = async ({ queryKey }) => {
		const [_, clientName] = queryKey
		const { data } = await mainApi.get(`businesspartners`, {
			params: {
				frozen: 'N',
				name: clientName,
				pageSize: '20',
			},
		})
		return JSON.parse(data)
	}

	const { data: bpData = [], isLoading: bpLoading } = useQuery({
		queryKey: ['clients', clientName],
		queryFn: fetchBp,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.docEntry}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage(currentPage - 1)
		}
	}

	const onSelectClient = (value) => {
		const val = value.split(' ,/ ')[0]
		setClient(val)
		debouncedSet(val, setClientName)
		const selectedOption = bpData.find((client) => {
			const cardName = get(client, 'cardName', '')
			const cardCode = get(client, 'cardCode', '')

			return `${cardName} ,/ ${cardCode}` === value
		})

		if (selectedOption) {
			const cardCode = get(selectedOption, 'cardCode', '')
			setClientCode(cardCode)
			message.success(cardCode)
		}
	}

	const columns = [
		{
			title: t('clientCode'),
			dataIndex: 'cardCode',
			key: 'cardCode',
			text: 'center',
			// width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('clientName'),
			dataIndex: 'cardName',
			key: 'cardName',
			text: 'center',
			// width: 300,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('phone'),
			dataIndex: 'u_Telephone',
			key: 'u_Telephone',
			text: 'center',
			// width: 150,
			render: (text) => (
				<span className="text-textColor">{formatPhoneNumber(text) || '-'}</span>
			),
		},
		{
			title: t('passport'),
			key: 'u_PS',
			dataIndex: 'u_PS',
			text: 'center',
			// width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('status'),
			dataIndex: 'U_Score',
			key: 'U_Score',
			text: 'center',
			// width: 150,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('address'),
			dataIndex: 'U_Score',
			key: 'address',
			text: 'center',
			// width: 150,

			render: (text, record) => (
				<span className="text-textColor">
					{`${get(record.bpAddresses[0], 'streetNo', '') ? get(record.bpAddresses[0], 'streetNo', '') : ''}
		${get(record.bpAddresses[0], 'buildingFloorRoom', '') ? get(record.bpAddresses[0], 'buildingFloorRoom', '') : ''} ` ||
						'-'}
				</span>
			),
		},
		{
			title: t('historyPurchase'),
			dataIndex: 'historyPurchase',
			key: 'historyPurchase',
			text: 'center',
			// width: 150,

			render: (text, record) => (
				<Button
					className={
						'bg-[#0A4D68] w-[130px] h-[30px] text-textColor rounded-xl'
					}
					onClick={(e) => UsersInvoice(record.cardCode, e)}
				>
					{t('historyPurchase')}
				</Button>
			),
		},
	]

	const searchMain = [
		{
			tag: 'autocomplete',
			title: t('client'),
			value: client,
			loading: bpLoading,
			onChange: (val) => {
				const value = val.includes(' ,/ ') ? val.split(' ,/ ')[0] : val
				setClient(value)
				debouncedSet(val, setClientName)
			},
			options: bpData.map((client) => ({
				value: `${client.cardName} ,/ ${client.cardCode}`,
				label: `${client.cardName}`,
			})),
			placeholder: t('client'),
			onSelect: onSelectClient,
		},
		{
			tag: 'input',
			title: t('phone'),
			onChange: (e) => {
				debouncedSet(e.target.value, setPhoneNumber)
			},
			placeholder: t('phone'),
		},

		{
			tag: 'button',
			title: '',
			onClick: () => {
				navigate('/createProducts-payment')
			},
			placeholder: t('add2'),
		},
	]
	const UsersInvoice = (v, e) => {
		e.stopPropagation()
		const url = `/users-invoices?CardCode=${v}`
		window.open(url, '_blank')
	}

	return (
		<Layout>
			<Main
				title={'MySales'}
				currentPage={currentPage + 1}
				handlePreviousPage={oldData}
				handleNextPage={newDatas}
				hasMoreData={data.length === 10}
				columns={columns}
				data={data}
				loading={isLoading}
				search={searchMain}
				// handleRowClick={viewItem}
			/>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<UpdateUser
				getRef={(ref) => (updateRef.current = ref)}
				onUpdated={refetch}
			/>
			<CreateUserModal
				isLoading={bpLoading}
				getRef={(r) => (createUserRef.current = r)}
				onConfirm={() =>
					succesCreateUserRef.current?.open('Клиент успешно создан')
				}
			/>
		</Layout>
	)
}

export default NoActiveClients
