import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import moment from 'moment'
import { mainApi } from '../../api/index'
import { useQuery } from '@tanstack/react-query'
import { message } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useTranslation } from 'react-i18next'
import { roundCurrency } from '../../utils/index'
import { debouncedSet } from 'utils/debounce'
import Main from '../../components/Main/index'

const AllProducts = () => {
	const errorRef = useRef()
	const { t } = useTranslation()
	const navigate = useNavigate()

	dayjs.extend(advancedFormat)
	dayjs.extend(customParseFormat)
	const dateFormat = 'YYYY-MM-DD'

	const [dateStart, setDateStart] = useState(
		dayjs().startOf('year').format(dateFormat),
	)
	const [dateEnd, setDateEnd] = useState(dayjs().format(dateFormat))
	const [client, setClient] = useState('')
	const [clientName, setClientName] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [imeiClient, setImeiClient] = useState('')
	const [currentPage, setCurrentPage] = useState(0)

	const fetchData = async ({ queryKey }) => {
		const [_, currentPage, imei, clientCode, client, dateStart, dateEnd] =
			queryKey
		const { data } = await mainApi.get(`salesinvoices`, {
			params: {
				type: 'manyinst',
				skip: currentPage,
				IntrSerial: imei || '',
				CardCode: client && clientCode ? clientCode : '',
				date1: dateStart ? dateStart : '',
				date2: dateEnd ? dateEnd + 'T22:00:00.000Z' : '',
				pageSize: '10',
			},
		})
		return JSON.parse(data)
	}

	const { data = [], isLoading } = useQuery({
		queryKey: [
			'installment-plan',
			currentPage,
			imeiClient,
			clientCode,
			clientName,
			dateStart,
			dateEnd,
		],
		queryFn: fetchData,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const fetchBp = async ({ queryKey }) => {
		const [_, clientName] = queryKey
		const { data } = await mainApi.get(`businesspartners`, {
			params: {
				frozen: 'N',
				name: clientName,
				pageSize: '20',
			},
		})
		return JSON.parse(data)
	}

	const { data: bpData = [], isLoading: bpLoading } = useQuery({
		queryKey: ['clients', clientName],
		queryFn: fetchBp,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.docEntry}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage(currentPage - 1)
		}
	}

	const onSelectClient = (value) => {
		const val = value.split(' ,/ ')[0]
		setClient(val)
		debouncedSet(val, setClientName)
		const selectedOption = bpData.find((client) => {
			const cardName = get(client, 'cardName', '')
			const cardCode = get(client, 'cardCode', '')

			return `${cardName} ,/ ${cardCode}` === value
		})

		if (selectedOption) {
			const cardCode = get(selectedOption, 'cardCode', '')
			setClientCode(cardCode)
			message.success(cardCode)
		}
	}

	const columns = [
		{
			title: t('clientCode'),
			dataIndex: 'cardCode',
			key: 'cardCode',
			text: 'center',
			// width: 150,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('clientName'),
			dataIndex: 'cardName',
			key: 'cardName',
			text: 'center',
			// width: 300,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: 'IMEI',
			dataIndex: 'intrSerial',
			key: 'intrSerial',
			text: 'center',
			// width: 150,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('product'),
			key: 'product',
			text: 'center',
			// width: 350,

			render: (text, record) => {
				const color = get(record, 'u_Color', '')
					? get(record, 'u_Color', '')
					: ''

				const memory = get(record, 'u_Memory', '')
					? `${get(record, 'u_Memory', '')} GB`
					: ''

				const condition = get(record, 'u_Condition', '')
					? get(record, 'u_Condition', '')
					: ''

				return (
					<span className="text-textColor">
						{get(record, 'dscription', '')} {color} {memory} {condition}
					</span>
				)
			},
		},
		{
			title: t('page'),
			dataIndex: 'u_DaftarBet',
			key: 'u_DaftarBet',
			text: 'center',
			// width: 150,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('totalPrice'),
			dataIndex: 'docTotal',
			key: 'docTotal',
			text: 'center',
			// width: 150,

			render: (text) => (
				<span className="text-textColor">
					{new Intl.NumberFormat('fr-FR', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})
						.format(roundCurrency(text || '-'))
						.replace(',', '.')}
				</span>
			),
		},
		{
			title: t('paid'),
			dataIndex: 'paidToDate',
			key: 'paidToDate',
			text: 'center',
			// width: 150,

			render: (text) => (
				<span className="text-textColor">
					{new Intl.NumberFormat('fr-FR', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})
						.format(text)
						.replace(',', '.')}
				</span>
			),
		},
		{
			title: t('remainder'),
			key: 'dueToDate',
			dataIndex: 'dueToDate',
			text: 'center',
			// width: 150,

			render: (text, record) => (
				<span className="text-textColor">
					{new Intl.NumberFormat('fr-FR', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})
						.format(text)
						.replace(',', '.')}
				</span>
			),
		},
		{
			title: t('dateOfSale'),
			dataIndex: 'docDate',
			key: 'docDate',
			text: 'center',
			// width: 150,
			render: (text) => (
				<span className="text-textColor">
					{text ? moment(text).format('DD-MM-YYYY') : '-'}
				</span>
			),
		},
	]

	const searchMain = [
		{
			tag: 'autocomplete',
			title: t('client'),
			value: client,
			loading: bpLoading,
			onChange: (val) => {
				const value = val.includes(' ,/ ') ? val.split(' ,/ ')[0] : val
				setClient(value)
				debouncedSet(val, setClientName)
			},
			options: bpData.map((client) => ({
				value: `${client.cardName} ,/ ${client.cardCode}`,
				label: `${client.cardName}`,
			})),
			placeholder: t('client'),
			onSelect: onSelectClient,
		},
		{
			tag: 'input',
			title: t('imei'),
			onChange: (e) => {
				debouncedSet(e.target.value, setImeiClient)
			},
			placeholder: t('imei'),
		},
		{
			tag: 'date',
			title: t('startDate'),
			value: dateStart ? dayjs(dateStart, dateFormat) : null,
			onChange: (date) => {
				setDateStart(date ? date.format('YYYY-MM-DD') : '')
			},
			placeholder: t('startDate'),
		},
		{
			tag: 'date',
			title: t('endDate'),
			value: dateEnd ? dayjs(dateEnd, dateFormat) : null,
			onChange: (date) => {
				setDateEnd(date ? date.format('YYYY-MM-DD') : '')
			},
			placeholder: t('endDate'),
		},
		{
			tag: 'button',
			title: '',
			onClick: () => {
				navigate('/createProducts-installment')
			},
			placeholder: t('add2'),
		},
	]

	return (
		<Layout>
			<Main
				title={'MySales'}
				currentPage={currentPage + 1}
				handlePreviousPage={oldData}
				handleNextPage={newDatas}
				hasMoreData={data.length === 10}
				columns={columns}
				data={data}
				loading={isLoading}
				search={searchMain}
				// handleRowClick={viewItem}
			/>

			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}
export default AllProducts
