import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
	TableOutlined,
} from "@ant-design/icons";
// import { setCollapse, setSelectedPath, setOpenMiniMenu } from "../../slice/mainSlice";
// import logo from "../../assets/images/BIS logo.svg";
// import "../../assets/style/menu.css";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { main } from '../../store/slices'

// import SalesIcon from "../../assets/icons/SalesSvg";
// import PaymentIcon from "../../assets/icons/PaymentSvg";
// import ClientIcon from "../../assets/icons/ClientSvg";
// import SalesStaticsIcon from "../../assets/icons/SalesStatics";
// import ReportsIcon from "../../assets/icons/ReportsSvg";
// import ItemsIcon from "../../assets/icons/itemsSvg";
import { get } from 'lodash';

export const Menubar = () => {
	const { t } = useTranslation();
	const { collapse, selectedPath, openMiniMenu,getMe,theme } = useSelector((state) => state.main);
	// const role = localStorage.getItem("role");
  const {
		setCollapse,
		setSelectedPath,
		setOpenMiniMenu,
		setTitle,
		setSubTitle,
	} = main.actions
	const dispatch = useDispatch();
	const [menuHeight, setMenuHeight] = useState("auto");
	const navigate = useNavigate();
	console.log("theme = ", theme)

	const data =
		get(getMe, 'Department2.Name', '') === 'DokonManager'
			? [
					{
						label: t('dashboard'),
						navigate: 'dashboard',
					},
					{
						title: t('invoices'),
						key: 'allProducts',
						children: [
							{
								title: t('invoice'),
								navigate: 'cash-payment',
							},
							{
								title: t('installment-plan'),
								navigate: 'installment-plan',
							},
						],
					},
					{
						title: t('icloud'),
						navigate: 'icloud',
					},
					{
						title: t('whsMovement'),
						key: 'warehouseM',
						children: [
							{
								title: t('purchases'),
								navigate: 'purchases',
							},
							{
								title: t('inventory-transfer'),
								navigate: 'inventory-transfer',
							},

							{
								title: t('whsReports'),
								navigate: 'reports',
							},
						],
					},

					{
						title: t('clients'),
						key: 'clients',
						children: [
							{
								title: t('active-clients'),
								navigate: 'active-clients',
							},
							{
								title: t('inactive-clients'),
								navigate: 'inactive-clients',
							},
						],
					},

					{
						title: t('cash'),
						key: 'cash',
						children: [
							{
								title: t('outgoing-payment'),
								navigate: 'outgoing-payment',
							},
							{
								title: t('cash-flow-report'),
								navigate: 'cash-flow-report',
							},
							{
								title: t('dds-report'),
								navigate: 'dds-report',
							},
							{
								title: t('refund'),
								navigate: 'refund',
							},
						],
					},
					{
						title: t('purchase-returns'),
						key: 'returns',
						children: [
							{
								title: t('purchase-returns'),
								navigate: 'purchase-returns',
							},
							{
								title: t('discounts'),
								navigate: 'forgivved',
							},
						],
					},

					{
						title: t('logout'),
						navigate: 'login',
					},
				]
			: get(getMe, 'Department2.Name', '') === 'Sotuv'
				? [
						{
							title: t('recovery'),
							navigate: 'recovery',
						},
						{
							title: t('whsMovement'),
							key: 'Transfers',
							children: [
								{
									title: t('addProduct'),
									navigate: 'addProduct',
								},
								{
									title: t('inventory-transfer'),
									navigate: 'inventory-transfer',
								},
								{
									title: t('purchases'),
									navigate: 'purchases',
								},
								{
									title: t('whsReports'),
									navigate: 'reports',
								},
							],
						},
						{
							title: t('invoices'),
							key: 'allProducts',
							children: [
								{
									title: t('invoice'),
									navigate: 'cash-payment',
								},
								{
									title: t('installment-plan'),
									navigate: 'installment-plan',
								},
							],
						},
						{
							title: t('purchases'),
							navigate: 'purchases',
						},

						{
							title: t('logout'),
							navigate: 'login',
						},
					]
				: get(getMe, 'Department2.Name', '') === 'Undiruv'
					? [
							{
								title: t('invoice'),
								navigate: 'undiruv-installment-plan',
							},
							{
								title: t('recovery'),
								navigate: 'recovery',
							},

							{
								title: t('logout'),
								navigate: 'login',
							},
						]
					: get(getMe, 'Department2.Name', '') === 'Undiruvchi1'
						? [
								{
									title: t('recovery'),
									navigate: 'recovery',
								},
								{
									title: t('dollarCourse'),
									navigate: 'dollarCourse',
								},
								{
									title: t('client'),
									key: 'clients',
									children: [
										{
											title: t('active-clients'),
											navigate: 'active-clients',
										},
										{
											title: t('inactive-clients'),
											navigate: 'inactive-clients',
										},
									],
								},
								{
									title: t('sms'),
									navigate: 'sms',
								},
								{
									title: t('logout'),
									navigate: 'login',
								},
							]
						: get(getMe, 'Department2.Name', '') === 'Undiruv_kocha'
							? [
									{
										title: t('recovery'),
										navigate: 'recovery',
									},
									{
										title: t('dollarCourse'),
										navigate: 'dollarCourse',
									},
									{
										title: t('client'),
										key: 'clients',
										children: [
											{
												title: t('active-clients'),
												navigate: 'active-clients',
											},
											{
												title: t('inactive-clients'),
												navigate: 'inactive-clients',
											},
										],
									},
									{
										title: t('sms'),
										navigate: 'sms',
									},
									{
										title: t('logout'),
										navigate: 'login',
									},
								]
							: get(getMe, 'Department2.Name', '') === 'Scoring'
								? [
										{
											title: t('clients'),
											navigate: 'scoring-clients',
										},
										{
											title: t('client'),
											key: 'clients',
											children: [
												{
													title: t('active-clients'),
													navigate: 'active-clients',
												},
												{
													title: t('inactive-clients'),
													navigate: 'inactive-clients',
												},
											],
										},
										{
											title: t('logout'),
											navigate: 'login',
										},
									]
								: 		get(getMe, 'Department2.Name', '') === 'Boshqaruv'
                ?
                [
										{
											title: t('dashboard'),
											navigate: 'dashboard',
										},

										{
											title: t('invoices'),
											key: 'allProducts',
											children: [
												{
													title: t('invoice'),
													navigate: 'cash-payment',
												},
												{
													title: t('installment-plan'),
													navigate: 'installment-plan',
												},
											],
										},
										{
											title: t('icloud'),
											navigate: 'icloud',
										},
										{
											title: t('clients'),
											key: 'clients',
											children: [
												{
													title: t('active-clients'),
													navigate: 'active-clients',
												},
												{
													title: t('inactive-clients'),
													navigate: 'inactive-clients',
												},
											],
										},
										{
											title: t('collectors'),
											key: 'reportRecovery',
											children: [
												{
													title: t('recovery'),
													navigate: 'recovery',
												},
												{
													title: t('recoveryReports'),
													navigate: 'recoveryChart',
												},
												{
													title: t('sms'),
													navigate: 'sms',
												},
											],
										},

										{
											title: t('whsMovement'),
											key: 'Transfers',
											children: [
												{
													title: t('purchases'),
													navigate: 'purchases',
												},
												{
													title: t('inventory-transfer'),
													navigate: 'inventory-transfer',
												},
												{
													title: t('whsReports'),
													navigate: 'reports',
												},
												{
													title: t('addProduct'),
													navigate: 'addProduct',
												},
											],
										},

										{
											title: t('credit-calculator'),
											navigate: 'settings',
										},
										{
											title: t('cash'),
											key: 'cash',
											children: [
												{
													title: t('outgoing-payment'),
													navigate: 'outgoing-payment',
												},
												{
													title: t('cash-flow-report'),
													navigate: 'cash-flow-report',
												},
												{
													title: t('dds-report'),
													navigate: 'dds-report',
												},
												{
													title: t('dollarCourse'),
													navigate: 'dollarCourse',
												},
												{
													title: t('refund'),
													navigate: 'refund',
												},
											],
										},

										{
											title: t('purchase-returns'),
											key: 'returns',
											children: [
												{
													title: t('purchase-returns'),
													navigate: 'purchase-returns',
												},
												{
													title: t('discounts'),
													navigate: 'forgivved',
												},
											],
										},

										{
											title: t('logout'),
											navigate: 'login',
										}
									]
                  :

                    {
											title: t('logout'),
											navigate: 'login',
										};
                    const pages = {
                      Wrhmanager: [
                        {
                          key: "purchasement",
                          label: t("purchasement"),
                          // icon: <SalesIcon />,
                          children: [
                            {
                              key: "purchase-order",
                              label: t("purchase-order"),
                            },
                            {
                              key: "purchase-incoming",
                              label: t("purchase-incoming"),
                            },
                          ],
                        },

                        {
                          key: "sales",
                          label: t("sales"),
                          // icon: <SalesIcon />,

                          children: [
                            {
                              key: "sales-order",
                              label: t("SalesOrder"),
                            },
                            {
                              key: "shipped-sales",
                              label: t("Shipped"),
                            },
                          ],
                        },
                      ],
                      "Sales Manager": [
                        {
                          key: "saleStatics",
                          label: t("saleStatics"),
                          // icon: <SalesStaticsIcon />,
                          children: [
                            {
                              key: "my-sales",
                              label: t("MySales"),
                            },
                            {
                              key: "debtors",
                              label: t("Accounts receivable"),
                            },
                          ],
                        },
                        {
                          key: "sales",
                          label: t("sales"),
                          icon: (
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M16.25 12.5H13.75"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                              <path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                          ),
                          children: [
                            {
                              key: "sales-order",
                              label: t("SalesOrder"),
                            },
                            {
                              key: "shipped-sales",
                              label: t("Shipped"),
                            },
                            {
                              key: "finished-sales",
                              label: t("finishedSales"),
                            },
                          ],
                        },
                        {
                          key: "clients",
                          label: t("clients"),
                          // icon: <ClientIcon />,
                        },
                      ],
                      Supplier: [
                        {
                          key: "purchasement",
                          label: t("purchasement"),
                          // icon: <SalesIcon />,
                          children: [
                            {
                              key: "purchase-order",
                              label: t("purchase-order"),
                            },
                            {
                              key: "purchase-incoming",
                              label: t("purchase-incoming"),
                            },
                            {
                              key: "purchase-invoices",
                              label: t("purchase-invoices"),
                            },
                          ],
                        },

                        {
                          key: "payments",
                          label: t("payments"),
                          // icon: <PaymentIcon />,
                          children: [
                            {
                              key: "outgoing-payments",
                              label: t("outgoingPayment"),
                            },
                            {
                              key: "incoming-payments",
                              label: t("incomingPayment"),
                            },
                          ],
                        },
                        {
                          key: "reports",
                          label: t("reports"),
                          // icon: <ReportsIcon />,
                          children: [
                            {
                              key: "reports",
                              label: t("reportsWhs"),
                            },
                            {
                              key: "send-money",
                              label: t("sendMoney"),
                            },
                          ],
                        },
                        {
                          key: "items",
                          label: t("items"),
                          // icon: <ItemsIcon />,
                        },
                        {
                          key: "suppliers",
                          label: t("suppliers"),
                          icon: <UserOutlined style={{ fontSize: "25px", color: "white" }} />,
                        },
                      ],
                      Cashier: [
                        {
                          key: "sales",
                          label: t("sales"),
                          // icon: <SalesIcon />,

                          children: [
                            {
                              key: "sales-order",
                              label: t("SalesOrder"),
                            },
                            {
                              key: "sales",
                              label: t("sales"),
                            },
                          ],
                        },
                        {
                          key: "payments",
                          label: t("payments"),
                          // icon: <PaymentIcon />,
                          children: [
                            {
                              key: "outgoing-payments",
                              label: t("outgoingPayment"),
                            },
                            {
                              key: "incoming-payments",
                              label: t("incomingPayment"),
                            },
                          ],
                        },
                        {
                          key: "cash",
                          label: t("kassa"),
                          // icon: <PaymentIcon />,
                          children: [
                            {
                              key: "balance",
                              label: t("balance"),
                            },
                            {
                              key: "send-money",
                              label: t("sendMoney"),
                            },
                          ],
                        },
                      ],
                      Boshqaruv: [
                          {
                            key: "dashboard",
                            label: t('dashboard'),
                            // icon: <UserOutlined style={{ fontSize: "25px", color: "white" }} />,
                          },

                        {
                          label: t('invoices'),
                          key: 'allProducts',
                          children: [
                            {
                              label: t('invoice'),
                              key: 'cash-payment',
                            },
                            {
                              label: t('installment-plan'),
                              key: 'installment-plan',
                            },
                          ],
                        },
                        {
                          label: t('icloud'),
                          key: 'icloud',
                        },
                        {
                          label: t('clients'),
                          key: 'clients',
                          children: [
                            {
                              label: t('active-clients'),
                              key: 'active-clients',
                            },
                            {
                              label: t('inactive-clients'),
                              key: 'inactive-clients',
                            },
                          ],
                        },
                        {
                          label: t('collectors'),
                          key: 'reportRecovery',
                          children: [
                            {
                              label: t('recovery'),
                              key: 'recovery',
                            },
                            {
                              label: t('recoveryReports'),
                              key: 'recoveryChart',
                            },
                            {
                              label: t('sms'),
                              key: 'sms',
                            },
                          ],
                        },

                        {
                          label: t('whsMovement'),
                          key: 'Transfers',
                          children: [
                            {
                              label: t('purchases'),
                              key: 'purchases',
                            },
                            {
                              label: t('inventory-transfer'),
                              key: 'inventory-transfer',
                            },
                            {
                              label: t('whsReports'),
                              key: 'reports',
                            },
                            {
                              label: t('addProduct'),
                              key: 'addProduct',
                            },
                          ],
                        },

                        {
                          label: t('credit-calculator'),
                          key: 'settings',
                        },
                        {
                          label: t('cash'),
                          key: 'cash',
                          children: [
                            {
                              label: t('outgoing-payment'),
                              key: 'outgoing-payment',
                            },
                            {
                              label: t('cash-flow-report'),
                              key: 'cash-flow-report',
                            },
                            {
                              label: t('dds-report'),
                              key: 'dds-report',
                            },
                            {
                              label: t('dollarCourse'),
                              key: 'dollarCourse',
                            },
                            {
                              label: t('refund'),
                              key: 'refund',
                            },
                          ],
                        },

                        {
                          label: t('purchase-returns'),
                          key: 'returns',
                          children: [
                            {
                              label: t('purchase-returns'),
                              key: 'purchase-returns',
                            },
                            {
                              label: t('discounts'),
                              key: 'forgivved',
                            },
                          ],
                        },

                        {
                          label: t('logout'),
                          key: 'login',
                        }
                      ]
                    };
	useEffect(() => {
		const menu = document.getElementById("menu");
		if (menu) {
			setMenuHeight(`${menu.scrollHeight}px`);
		}
	}, [collapse]);

	const toggleCollapsed = () => {
		dispatch(setCollapse(!collapse));
	};
	const onSelectMenuItem = (item) => {
		console.log(item)
		dispatch(setSelectedPath(item.key));
		navigate(`/${item.key}`);
		dispatch(setTitle(item.key));

	};

	const items = pages[get(getMe, 'Department2.Name', '')];

	return (
		<div className="flex ">
			<div className={`${collapse ? "w-[80px]" : "w-[250px]"} h-${menuHeight} bg-white dark:bg-[#131525]`}>
				{/* <div className="flex gap-10 pt-7 text-center"> */}
				<h1 className="text-textColor text-center pt-10 text-xl font-bold">Bclouds Nasiya</h1>
				{/* </div> */}
				<div className="mt-10">
					<Menu
						defaultOpenKeys={openMiniMenu}
						selectedKeys={[selectedPath]}
						mode="inline"
						inlineCollapsed={collapse}
						items={items}
						className="bg-transparent m-auto"
						theme={theme}
						onSelect={onSelectMenuItem}
						onOpenChange={(v) => {
							dispatch(setOpenMiniMenu(v));
							console.log("v=",v)

							dispatch(setSubTitle(v));

						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Menubar;