import React from 'react'
import ButtonStyle from './ButtonStyle'
import ClipLoader from 'react-spinners/ClipLoader'
import {Button as AntButton} from 'antd'
import { useTranslation } from 'react-i18next';
const Button = ({
	loading,
	children,
	onClick,
	btnStyle,
	hoverBtnStyle,
	disabled = false,
	className,
	hoverClassName,
	text,
 ...rest
}) => {
	const { t } = useTranslation()
	return (
		// <ButtonStyle>
		// 	<button
		// 		disabled={isLoading || disabled}
		// 		type={'button'}
		// 		onClick={onClick}
		// 		className={'btn p-4 ' + className}
		// 		style={btnStyle}
		// 	>
		// 		{isLoading && (
		// 			<ClipLoader
		// 				loading={true}
		// 				size={10}
		// 				aria-label="Loading Spinner"
		// 				data-testid="loader"
		// 			/>
		// 		)}
		// 		{children}
		// 	</button>
		// </ButtonStyle>
		<AntButton loading={loading} onClick={onClick} disabled={loading || disabled} className={'min:w-10 min:h-10 p-2 text-white bg-[#0A4D68] ' + className} {...rest}>
{children}		</AntButton>
	)
}

export default Button
