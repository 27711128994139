import React, { useRef, useState } from 'react'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import CreateIcloudModal from '../../components/Modal/CreateIcloud'
import UpdateUser from './UpdateIcloud'
import { mainApi } from '../../api/index'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Main from '../../components/Main/index'
import { debouncedSet } from 'utils/debounce'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'

const IcloudPage = () => {
	const errorRef = useRef()
	const updateRef = useRef()
	const createIcloudRef = useRef()
	const succesCreateUserRef = useRef()
	const { t } = useTranslation()

	const [currentPage, setCurrentPage] = useState(0)

	const fetchData = async ({ queryKey }) => {
		const [_, page, name] = queryKey
		const { data } = await mainApi.get(`security`, {
			params: {
				skip: page,
				pageSize: '10',
			},
		})
		return JSON.parse(data)
	}

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['security', currentPage],
		queryFn: fetchData,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const viewItem = (v) => {
		updateRef.current?.open(v)
	}

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 1)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (currentPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage(currentPage - 1)
		}
	}

	const columns = [
		{
			title: t('code'),
			dataIndex: 'code',
			key: 'code',
			text: 'center',
			width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('icloud'),
			dataIndex: 'name',
			key: 'name',
			text: 'center',
			width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('phone'),
			dataIndex: 'u_Whichtelephone',
			key: 'u_Whichtelephone',
			text: 'center',
			width: 150,
			render: (text) => (
				<span className="text-textColor">{formatPhoneNumber(text) || '-'}</span>
			),
		},
		{
			title: t('password'),
			key: 'u_Password',
			dataIndex: 'u_Password',
			text: 'center',
			width: 150,
			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('status'),
			dataIndex: 'u_Status',
			key: 'u_Status',
			text: 'center',
			width: 150,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
		{
			title: t('typeAccount'),
			dataIndex: 'u_TypeAccount',
			key: 'u_TypeAccount',
			text: 'center',
			width: 150,

			render: (text) => <span className="text-textColor">{text || '-'}</span>,
		},
	]

	const searchMain = [
		{
			tag: 'button',
			title: '',
			placeholder: t('add2'),
			onClick: () => {
				createIcloudRef.current?.open()
			},
		},
	]

	return (
		<Layout>
			<Main
				title={'MySales'}
				currentPage={currentPage + 1}
				handlePreviousPage={oldData}
				handleNextPage={newDatas}
				hasMoreData={data.length === 10}
				columns={columns}
				data={data}
				loading={isLoading}
				search={searchMain}
				handleRowClick={viewItem}
			/>

			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
				<UpdateUser
					getRef={(ref) => (updateRef.current = ref)}
					onUpdated={refetch}
				/>
				<CreateIcloudModal
					getRef={(r) => (createIcloudRef.current = r)}
					onConfirm={() => {
						succesCreateUserRef.current?.open('Аккаунт защиты успешно создан')
					}}
					onUpdated={refetch}
				/>
			</>
		</Layout>
	)
}

export default IcloudPage
