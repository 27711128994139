import React from 'react'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import Navigation from './navigators'
import './i18n'
import './assets/antButton.css'
import { QueryClient ,QueryClientProvider} from '@tanstack/react-query';

export const queryClient = new QueryClient();
function App() {
	return (
		<QueryClientProvider client={queryClient}>

		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Navigation />
			</PersistGate>
		</Provider>
		</QueryClientProvider>

	)
}

export default App
