import React, { memo, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { get } from 'lodash'
import api, { customPhotoUploadApi } from '../../../../api'
import { ErrorModal } from '../../../../components/Modal'
import CreateUserStyle from '../../../../components/Modal/ScoreUserCreate/CreateUserStyle'
import Button from '../../../../components/Button'
import { useSelector } from 'react-redux'
import customMainApi from '../../../../api'
import DistrictData from '../../../../components/Modal/ScoreUserCreate/Regions/districts.json'
import VillageData from '../../../../components/Modal/ScoreUserCreate/Regions/villages.json'
import ClipLoader from 'react-spinners/ClipLoader'
import formatPhoneNumber from '../../../../helpers/PhoneWithSpaces'
import { message, Input, Button as AntButton } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		height: '90%',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 'none',
		borderRadius: 15,
		overflowX: 'auto',
	},
}

const UpdateUserModal = (props) => {
	const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props
	const { getMe } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const errorRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [score, setScore] = useState('')
	const [U_Created, setU_Created] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [addresses, setAddress] = useState([])

	const [districtData, setDistrictData] = useState([])
	const [regionsData, setRegionsData] = useState([])
	const [citiesData, setCitiesData] = useState([])

	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')
	const [comment, setComment] = useState('')
	const [comment2, setComment2] = useState('')

	const [frozen, setFrozen] = useState('')
	const [userFields, setUserFields] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [bpLoading, setBpLoading] = useState(false)
	const [address2, setAddress2] = useState('')
	const [ageData, setAgeData] = useState('')
	const [path, setPath] = useState([])

	const [employeeData, setEmployeeData] = useState([])
	const [language, setLanguage] = useState('')
	const [bornAdress, setBornAdress] = useState('')
	const [salary, setSalary] = useState('')

	const role =
		get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
		get(getMe, 'Department2.Name', '') === 'Scoring'

	useEffect(() => {
		const ref = {
			open: (data) => {
				setIsOpenModal(true)

				setCardCode(
					get(data, 'cardCode', null) !== null ? get(data, 'cardCode', '') : '',
				)

				getUserInfo(get(data, 'cardCode', ''))
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	useEffect(() => {
		fetchRegions()
		// getEmployess()
	}, [])

	const getEmployess = (id) => {
		try {
			api(`EmployeesInfo(${id})`).then((res) => {
				const data = JSON.parse(res.data)
				setEmployeeData(JSON.parse(res.data))
				setU_Created(
					`${get(data, 'FirstName', '')} ${get(data, 'LastName', '')} `,
				)
			})
		} catch (err) {
			console.error('Error in getting Employees in Active Clients Update', err)
		}
	}

	const getUserInfo = (cardCode) => {
		setBpLoading(true)

		try {
			api(
				`BusinessPartners('${cardCode}')?$select=CardName,U_Comment,U_PhotoId,U_Salary,U_age_data,FreeText,Frozen,U_workplace,U_Telephone,U_address,U_Respawn,U_Language,U_Gender,U_PS,U_KATM,U_Score,U_MIB,U_Nasiya,U_Created,BPAddresses`,
			).then((res) => {
				const data = JSON.parse(res.data)
				setUserName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[1]
						: '',
				)
				setUserFathersName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[2] +
								' ' +
								(get(data, 'CardName', '').split(' ')[3] || '')
						: '',
				)
				setUserSureName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[0]
						: '',
				)
				const bpAddresses = get(data, 'BPAddresses', [])

				setWorkplace(get(data, 'U_workplace', ''))
				setPhone(formatPhoneNumber(get(data, 'U_Telephone', '')))
				setMale(get(data, 'U_Gender', ''))
				setPassport(get(data, 'U_PS', ''))
				setScore(get(data, 'U_Score', ''))

				setSalary(get(data, 'U_Salary', ''))

				setLanguage(get(data, 'U_Language', ''))
				setComment(get(data, 'FreeText', '-'))
				setComment2(get(data, 'U_Comment', ''))
				setAddress2(get(data, 'U_address', ''))
				const ageData = get(data, 'U_age_data', '')
				setAgeData(ageData ? moment(ageData).format('YYYY-MM-DD') : '')
				setAddress(
					Array.isArray(bpAddresses) && bpAddresses.length > 0
						? bpAddresses
						: [
								{
									AddressName: 1, // Auto-generate something here if needed
									RowNum: 0,
									BPCode: cardCode,
									U_CityCode: '',
									U_DistrictCode: '',
									U_RegionCode: '',
									StreetNo: '',
									BuildingFloorRoom: '',
									U_Apartment: '',
								},
							],
					// :[]
				)

				setBornAdress(get(data, 'U_Respawn', ''))
				setFrozen(get(data, 'Frozen', ''))
				const photoId = get(data, 'U_PhotoId', '')

				const photoPaths = photoId
					? photoId.includes(',')
						? photoId.split(',')
						: [photoId]
					: []

				const initialPath = photoPaths.map((path) => ({
					file: 'not empty',
					has: true,
					path,
					imgLoading: false,
				}))

				const finalPath =
					initialPath.length > 0
						? initialPath
						: [{ file: null, path: '', imgLoading: false }]

				setPath(finalPath)
				if (data?.U_Created) {
					getEmployess(data?.U_Created)
				} else {
					setU_Created('')
				}
			})
		} catch (error) {
			console.error('Error in getting Bp Info in Active CLients', error)
		} finally {
			setBpLoading(false)
		}
	}

	const handleFileChange = (event, index) => {
		const updatedPath = [...path]
		updatedPath[index].file = event.target.files[0]
		setPath(updatedPath)
	}

	const uploadImg = async (index) => {
		const image = path[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = true
			setPath(updatedPath)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})

			const { key } = res.data
			updatedPath[index].path = key
			setPath(updatedPath)

			message.success(t('sucUploaded'))
		} catch (err) {
			message.error(t('errorDownload'))
		} finally {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = false
			setPath(updatedPath)
		}
	}

	// View the uploaded image
	const viewImage = async (index) => {
		const image = path[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error(t('errorDownload'))
		}
	}

	// Add a new empty row for uploading images
	const addNewRow = () => {
		setPath([...path, { file: null, path: '', imgLoading: false }])
	}

	const deleteImage = async (index) => {
		const image = path[index]
		let url = `BusinessPartners('${cardCode}')`

		try {
			const res = await customPhotoUploadApi.delete(`assets/${image.path}`)
			const photoId = path
				.map((images) => images.path)
				.filter((p) => p !== image.path) // Correct filter syntax
				.join(',')
			await api.patch(url, {
				U_PhotoId: photoId,
			})

			deleteRow(index) // Remove the image from the state after deletion
			message.success(t('delPhoto'))
		} catch (error) {
			message.error(t('errorDelPhoto'))
		}
	}

	// Delete a specific row
	const deleteRow = (index) => {
		setPath(path.filter((_, i) => i !== index))
	}

	const fetchDistrictsWithoutId = () => {
		api
			.get(`U_ADDRESS_DISCRTICT`, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				setDistrictData(items)
			})
	}

	const fetchDistricts = (cityCode) => {
		if (cityCode.trim()) {
			api
				.get(`U_ADDRESS_DISCRTICT?$filter=U_CityCode eq '${cityCode}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					setDistrictData(items)
				})
				.catch((error) => {
					console.error(
						'Error fetching districts in Active Clients Update:',
						error,
					)
				})
		}
	}

	const fetchCities = (rgId) => {
		// ?$filter=U_RegionCode eq rgId
		if (rgId.trim()) {
			api
				.get(`U_ADDRESS_CITY?$filter=U_RegionCode eq '${rgId}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					setCitiesData(items)
				})
		}
	}
	const fetchCitiesWithoutId = () => {
		// ?$filter=U_RegionCode eq rgId

		api
			.get(`U_ADDRESS_CITY`, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				setCitiesData(items)
			})
	}

	const fetchRegions = () => {
		api.get('U_ADDRESS_REGION').then((res) => {
			const items = JSON.parse(res.data).value
			setRegionsData(items)
		})
	}

	const handleAddressChange = (index, field, value) => {
		const newAddresses = addresses.map((address, i) => {
			if (i === index) {
				return { ...address, [field]: value }
			}
			return address
		})
		setAddress(newAddresses)
	}

	const submit = () => {
		setIsLoading(true)
		let url = `BusinessPartners('${cardCode}')`
		const photoId = path
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		api
			.patch(url, {
				U_workplace: workplace,
				CardName: `${userSureName} ${userName} ${userFathersName}`, //UserName, userSurname, userFathersName,
				U_Telephone: `${phone}`,
				Currency: '##', // Bydefault
				U_Gender: male, // Erkak Ayol
				U_PS: passport, //Pasport
				// U_Score: Number(score.split(' ')[0]),
				U_Respawn: bornAdress,
				Frozen: frozen,
				Valid: frozen === 'tNO' ? 'tYES' : 'tNO',
				U_Created: get(getMe, 'EmployeeID', ''),
				U_Language: language,
				U_Salary: salary,
				U_age_data: ageData,
				U_PhotoId: photoId,
				FreeText: comment,
				U_Comment: comment2,
				BPAddresses: addresses.map((address, idx) => ({
					AddressName: idx + 1, // Auto-generate something here if needed
					RowNum: address.RowNum,
					BPCode: address.BPCode,
					U_CityCode:
						address.U_CityCode && address.U_CityCode.length > 4
							? Number(address.U_CityCode.split(' - ')[1])
							: address.U_CityCode, // Village
					U_DistrictCode:
						address.U_DistrictCode && address.U_DistrictCode.length > 4
							? Number(address.U_DistrictCode.split(' - ')[1])
							: address.U_DistrictCode, // District
					U_RegionCode:
						address.U_RegionCode && address.U_RegionCode.length > 4
							? Number(address.U_RegionCode.split(' - ')[1])
							: address.U_RegionCode, // Region
					BuildingFloorRoom: address.BuildingFloorRoom,
					StreetNo: address.StreetNo,
					U_Apartment: address.U_Apartment,
				})),
			})
			.then(() => {
				onUpdated()
				setIsOpenModal(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => setIsLoading(false))
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
		fetchCitiesWithoutId()
		fetchDistrictsWithoutId()
	}, [])

	const getRegion = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = DistrictData.filter((v) => {
			return a === Number(get(v, 'region_id', 0))
		})
		setDisData(changed_data)
		setRegion(text)
		setDistrict('')
		setVillage('')
	}

	const getDistrict = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = VillageData.filter((v) => {
			return a === Number(get(v, 'district_id', 0))
		})
		setVillData(changed_data)
		setDistrict(text)
		setVillage('')
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">{t('editUser')}</p>
					{bpLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="flex flex-col gap-5">
								<div className="flex flex-col sm:flex-row items-center gap-5">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('lastName')}</p>
										<input
											type="text"
											placeholder={t('lastName')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
											}
											disabled={!role}
											value={userSureName}
											onChange={(v) => setUserSureName(v.target.value)}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start  w-full">
										<p>{t('name')}</p>
										<input
											type="text"
											placeholder={t('name')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
											}
											value={userName}
											onChange={(v) => setUserName(v.target.value)}
											disabled={!role}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('fatherName')}</p>
										<input
											type="text"
											placeholder={t('fatherName')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
											}
											value={userFathersName}
											onChange={(v) => setUserFathersName(v.target.value)}
											disabled={!role}
										/>
									</div>
								</div>
								{addresses.map((address, index) => {
									const region = regionsData?.find(
										(value) =>
											Number(address.U_RegionCode) === Number(value.Code),
									)
									const displayRegion = region ? `${region.Name}` : ''

									const city = citiesData?.find(
										(value) =>
											Number(address.U_CityCode) === Number(value.Code),
									)
									const displayCity = city ? `${city.Name}` : ''

									const district = districtData?.find(
										(value) =>
											Number(address.U_DistrictCode) === Number(value.Code),
									)
									const displayDistrict = district ? `${district.Name}` : ''

									return (
										<div className="flex flex-col gap-5 mt-4" key={index}>
											{index === 0 ? (
												<b>{t('addressReg')}</b>
											) : (
												<b>{t('addressLive')}</b>
											)}
											<hr className="w-full border-1 font-extrabold border-black" />

											<div className="flex flex-col sm:flex-row items-center gap-2">
												<div className="flex  flex-col gap-2 items-start w-full">
													<p>{t('region')}</p>
													<input
														type="text"
														placeholder={t('region')}
														list="region"
														defaultValue={displayRegion}
														onChange={(v) => {
															getRegion(v.target.value)
															fetchCities(
																v.target.value.split(' - ')[1]
																	? v.target.value.split(' - ')[1]
																	: ' ',
															)
															handleAddressChange(
																index,
																'U_RegionCode',
																v.target.value,
															)
														}}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
														}
														disabled={!role}
													/>
													<datalist id="region">
														{regionsData.map((v, i) => (
															<option key={i} value={`${v.Name} - ${v.Code}`} />
														))}
													</datalist>
												</div>
												<div className="flex flex-col items-start w-full gap-2">
													<p>{t('village')}</p>
													<input
														type="text"
														placeholder={t('village')}
														list="village"
														defaultValue={displayCity}
														onChange={(v) => {
															setVillage(v.target.value)
															fetchDistricts(
																v.target.value.split(' - ')[1]
																	? v.target.value.split(' - ')[1]
																	: ' ',
															)
															handleAddressChange(
																index,
																'U_CityCode',
																v.target.value,
															)
														}}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
														}
														disabled={!role}
													/>
													<datalist id="village">
														{citiesData.map((v, i) => (
															<option key={i} value={`${v.Name} - ${v.Code}`} />
														))}
													</datalist>
												</div>
												<div className="flex flex-col gap-2 items-start w-full">
													<p>{t('district')}</p>
													<input
														type="text"
														placeholder={t('district')}
														list="district"
														defaultValue={displayDistrict}
														onChange={(v) => {
															getDistrict(v.target.value)
															handleAddressChange(
																index,
																'U_DistrictCode',
																v.target.value,
															)
														}}
														disabled={!role}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
														}
													/>
													<datalist id="district">
														{districtData.map((v, i) => (
															<option key={i} value={`${v.Name} - ${v.Code}`} />
														))}
													</datalist>
												</div>
											</div>
											<div className="flex items-center gap-2 w-full flex-col sm:flex-row">
												<div className="flex flex-col gap-2 items-start w-full ">
													<p>{t('streetName')}</p>
													<input
														type="text"
														placeholder={t('streetName')}
														value={address.StreetNo}
														onChange={(v) =>
															handleAddressChange(
																index,
																'StreetNo',
																v.target.value,
															)
														}
														disabled={!role}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
														}
													/>
												</div>
												<div className="flex flex-col items-start gap-2 w-full">
													<p>{t('buildingRoom')}</p>
													<input
														type="text"
														placeholder={t('buildingRoom')}
														value={address.BuildingFloorRoom}
														disabled={!role}
														onChange={(v) =>
															handleAddressChange(
																index,
																'BuildingFloorRoom',
																v.target.value,
															)
														}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
														}
													/>
												</div>
												<div className="flex flex-col items-start gap-2 w-full">
													<p>{t('apartmentNum')}</p>
													<input
														type="text"
														placeholder={t('apartmentNum')}
														value={address.U_Apartment}
														disabled={!role}
														onChange={(v) =>
															handleAddressChange(
																index,
																'U_Apartment',
																v.target.value,
															)
														}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
														}
													/>
												</div>
											</div>
										</div>
									)
								})}
								<div className="flex items-center gap-2 flex-col sm:flex-row">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('passport/pinfl')}</p>
										<input
											type="text"
											placeholder={t('passport/pinfl')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full h-10 '
											}
											value={passport}
											disabled={!role}
											onChange={(v) => setPassport(v.target.value)}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('phone')}</p>
										<textarea
											type="text"
											placeholder={t('phone')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={phone}
											disabled={!role}
											onChange={(v) => setPhone(v.target.value)}
										/>
									</div>
								</div>{' '}
								<div className="flex items-center gap-2 flex-col sm:flex-row">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('bornAddress')}</p>
										<input
											type="text"
											placeholder={t('bornAddress')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
											}
											value={bornAdress}
											disabled={!role}
											onChange={(v) => setBornAdress(v.target.value)}
										/>
									</div>

									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('bornDate')}</p>
										<input
											type="date"
											placeholder={t('bornDate')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={ageData}
											disabled={!role}
											onChange={(v) => setAgeData(v.target.value)}
										/>
									</div>
								</div>
								<div className="flex items-center gap-2 flex-col sm:flex-row">
									<div className="flex flex-col items-start gap-2 w-full">
										<p>{t('salary')}</p>
										<input
											type="text"
											placeholder={t('salary')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={salary}
											disabled={!role}
											onChange={(v) => setSalary(v.target.value)}
										/>
									</div>
									<div className="flex flex-col items-start gap-2 w-full">
										<p>{t('workplace')}</p>
										<input
											type="text"
											placeholder={t('workplace')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={workplace}
											disabled={!role}
											onChange={(v) => setWorkplace(v.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className="flex items-center  mt-5 gap-2 flex-col sm:flex-row">
								<div className="flex flex-col gap-2  items-start w-full">
									<p>{t('status')}</p>
									<select
										placeholder={t('status')}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 w-full'
										}
										value={score}
										disabled={!role}
										onChange={(v) => setScore(v.target.value)}
									>
										{userFields
											?.find((item) => item.Name === 'Score')
											?.ValidValuesMD.map((value) => (
												<option value={value.Value}>
													{' '}
													{value.Value} - {value.Description}
												</option>
											))}
									</select>
								</div>
								<div className="flex flex-col items-start gap-2 w-full">
									<p>{t('comment')}</p>
									<input
										type="text"
										placeholder={t('comment')}
										value={comment}
										disabled={!role}
										onChange={(e) => {
											setComment(e.target.value)
										}}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
										}
									/>
								</div>
							</div>
							<div className="flex flex-col items-start gap-2 w-full mt-5 ">
								<p>{t('comment')}</p>
								<textarea
									placeholder={`${t('comment')}`}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									disabled={!role}
									value={comment2}
									onChange={(v) => setComment2(v.target.value)}
								/>
							</div>
							<div className="flex items-center mt-5 gap-2 flex-col sm:flex-row">
								<div className="flex flex-col gap-2 items-start w-full">
									<p>{t('smsSendLan')}</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
										}
										value={language}
										disabled={!role}
										onChange={(e) => setLanguage(e.target.value)}
									>
										<option value="" disabled>
											{t('smsSendLan')}
										</option>

										{userFields
											?.find((item) => item.Name === 'Language')
											?.ValidValuesMD.map((value) => (
												<option value={value.Value}>{value.Description}</option>
											))}
									</select>
								</div>
								<div className="flex flex-col items-start gap-2  w-full">
									<p>{t('creator')}</p>
									<input
										type="text"
										// placeholder="Коментария"
										value={U_Created}
										disabled
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
										}
									/>
								</div>
							</div>
							<div className="flex flex-col items-start gap-2 w-full ">
								<p>{t('address2')}</p>
								<textarea
									placeholder={`${t('address2')}`}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									disabled={true}
									value={address2}
								/>
							</div>

							<div className="mt-10">
								{path.map((image, index) =>
									image.has ? (
										image.path && (
											<div
												key={index}
												className="flex w-full items-start flex-col gap-1 mt-5 mb-10"
											>
												<div>
													<p className="font-bold">
														{t('photo')}-{index + 1}
													</p>
												</div>
												<div className="flex flex-col sm:flex-row items-center gap-3 w-full">
													<AntButton
														onClick={() => viewImage(index)}
														className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
													>
														{t('download')}{' '}
													</AntButton>
													<AntButton
														onClick={() => deleteImage(index)}
														disabled={!role}
														className="h-[40px] w-full sm:w-[150px] rounded-lg p-2 bg-red-500 text-white"
													>
														{t('delete')}
													</AntButton>
													{index === path.length - 1 && (
														<AntButton
															onClick={addNewRow}
															disabled={!role}
															className="h-10 w-full  sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
														>
															{t('add2')}
														</AntButton>
													)}
												</div>
											</div>
										)
									) : (
										<div
											key={index}
											className="flex w-full items-start flex-col  gap-1 mt-5 mb-10"
										>
											<div>
												<p className="font-bold">
													{t('photo')}-{index + 1}
												</p>
											</div>
											<div className="flex flex-col sm:flex-row items-center gap-3 w-full">
												<Input
													type="file"
													onChange={(e) => handleFileChange(e, index)}
													className="w-full"
													disabled={!role}
												/>
												<AntButton
													onClick={() => uploadImg(index)}
													isLoading={image.imgLoading}
													disabled={!role}
													className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													{t('upload')}
												</AntButton>
												{image.path && (
													<>
														<AntButton
															onClick={() => viewImage(index)}
															className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
														>
															{t('download')}
														</AntButton>
														<AntButton
															onClick={() => deleteImage(index)}
															disabled={!role}
															className="h-[40px] w-full sm:w-[150px] rounded-lg p-2 bg-red-500 text-white"
														>
															{t('delete')}
														</AntButton>
													</>
												)}
												{index !== 0 && (
													<AntButton
														onClick={() => deleteRow(index)}
														disabled={!role}
														className="h-10 w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
													>
														{t('cancel')}
													</AntButton>
												)}
												{index === path.length - 1 && (
													<AntButton
														onClick={addNewRow}
														disabled={!role}
														className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
													>
														{t('add2')}
													</AntButton>
												)}
											</div>
										</div>
									),
								)}
							</div>

							<div className="flex items-center  mt-5 my-5 flex-col sm:flex-row">
								<div className="flex items-center  gap-7 w-full">
									<h1>{t('active')} :</h1>

									<div className="flex  gap-1">
										<input
											type="radio"
											id="yes"
											name="frozen"
											value="Да"
											disabled={!role}
											checked={frozen === 'tNO'}
											onChange={(v) =>
												setFrozen(v.target.value === 'Да' ? 'tNO' : 'tYES')
											}
										/>

										<label htmlFor="yes">{t('yes')}</label>
									</div>

									<div className="flex gap-1">
										<input
											type="radio"
											id="no"
											name="frozen"
											value="Нет"
											disabled={!role}
											checked={frozen === 'tYES'}
											onChange={(v) =>
												setFrozen(v.target.value === 'Нет' ? 'tYES' : 'tNO')
											}
										/>
										<label htmlFor="no">{t('no')}</label>
									</div>
								</div>
								<div className="flex gap-4 items-center w-full mt-3">
									<div className="flex gap-1">
										<input
											type="radio"
											id="male"
											name="fav_language"
											checked={male === 'Male'}
											disabled={!role}
											onChange={(v) => {
												setMale(v.target.value)
											}}
											value="Male"
										/>

										<label htmlFor="male">{t('male')}</label>
									</div>
									<div className="flex gap-1">
										<input
											type="radio"
											id="female"
											name="fav_language"
											disabled={!role}
											checked={male === 'Female'}
											onChange={(v) => {
												setMale(v.target.value)
											}}
											value="Female"
										/>
										<label htmlFor="female">{t('female')}</label>
									</div>
								</div>
							</div>

							{role ? (
								<div className="flex items-center justify-between">
									<AntButton
										className="bg-red-500 h-10 text-white py-2 px-4 rounded-md w-full sm:w-auto"
										onClick={() => {
											onClose()
											setIsOpenModal(false)
										}}
									>
										{t('no')}
									</AntButton>
									<AntButton
										className="bg-[#0A4D68] h-10 text-white py-2 px-4 rounded-md  w-full sm:w-auto"
										loading={isLoading}
										onClick={submit}
									>
										{t('yes')}
									</AntButton>
								</div>
							) : (
								''
							)}
						</>
					)}
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</Modal>
	)
}

export default memo(UpdateUserModal)
